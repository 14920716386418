import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MustMatch } from '../core/services';
import { 
	FormBuilder, 
	FormGroup, 
	Validators, 
	ReactiveFormsModule 
} from '@angular/forms';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {

	passwordChangeForm: FormGroup;
    submitted = false;
	hide = true;
  	constructor(
  		private _bottomSheetRef: MatBottomSheetRef<PasswordChangeComponent>,
  		private formBuilder: FormBuilder,
  	) { }

  	get f() { return this.passwordChangeForm.controls; }

  	onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.passwordChangeForm.invalid) {
            return;
        }

        // display form values on success
        alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.passwordChangeForm.value, null, 4));
    }

    onReset() {
        this.submitted = false;
        this.passwordChangeForm.reset();
    }

  	ngOnInit() {
  		this.passwordChangeForm = this.formBuilder.group({
            newpassword: ['', [Validators.required, Validators.minLength(8)]],
            confirmPassword: ['', Validators.required],
        }, {
            validator: MustMatch('newpassword', 'confirmPassword')
        });
  	}

}
