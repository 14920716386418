import { 
    Component,
    ChangeDetectorRef,
    OnDestroy,
    NgZone,
} from '@angular/core';

import { 
    ElectronService,
    CustomerService,
    EmotionService,
    Customer,
    Emotion,
} from './core/services';

import { TranslateService } from '@ngx-translate/core';
import { AppConfig } from '../environments/environment';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { PasswordChangeComponent } from './password-change/password-change.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

    mobileQuery: MediaQueryList;
    logedIn = false;
    private _mobileQueryListener: () => void;

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public electronService: ElectronService,
        private translate: TranslateService,
        private _emoSer: EmotionService,
        private _custSer: CustomerService,
        private router: Router,
        private ngZone: NgZone,
        private _bottomSheet: MatBottomSheet,
    ) {

        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addListener(this._mobileQueryListener);
        
        translate.setDefaultLang('en');
        // console.log('AppConfig', AppConfig);

        if (electronService.isElectron) {
            // console.log(process.env);
            console.log('Mode electron');
            // console.log('Electron ipcRenderer', electronService.ipcRenderer);
            // console.log('NodeJS childProcess', electronService.childProcess);

            this.electronService.ipcRenderer.on("NOTIFICATION_CLICKED",
                (_, data) => {
                    this.ngZone.run(() => this.router.navigateByUrl('/profile/'+data)).then();
            })      

            this.electronService.ipcRenderer.on("NEW_CUSTOMER", (_, data) => {

                this._custSer.addCustomer(<Customer>{
                    age : eval(data.age),
                    gender : data.gender === '0' ? "Male" : "Female",
                    emotion : data.emotion.replace(/'/g, ""),
                    timestamp : new Date(data.date).toLocaleString(),
                    camera : data.camera,
                    id : data.id,
                    // image : data.image ? 'data:image/png;base64,' +data.image : "",
                    image : "https:/ec2-15-188-3-68.eu-west-3.compute.amazonaws.com:8443/facade/public/profilePicture/"+data.id,
                });

                this._emoSer.addEmotion(<Emotion>{
                    customer: data.id,
                    emotion: data.emotion.replace(/'/g, ""),
                    timestamp: new Date(data.date).toLocaleString()
                })
            });
        } else {
            console.log('Mode web');
        }
    }

    openChangePassword(): void {
        this._bottomSheet.open(PasswordChangeComponent);
    }
    
    ngOnDestroy(): void {
        this.mobileQuery.removeListener(this._mobileQueryListener);
    }
}
