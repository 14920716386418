import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { 
	Customer, 
	Emotion,
	CustomerService,
	EmotionService,
	HttpService, 
  ElectronService
} from '../core/services';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})

export class ProfileComponent implements OnInit {

	customer : Customer;
    emotions : Emotion[]=[];
	id = this._route.snapshot.paramMap.get('id');
    profile_pic = ""

  	constructor(
  		private _route: ActivatedRoute,
  		private _custSer: CustomerService,
        private _emoSer: EmotionService,
        private location: Location,
        private _http: HttpService,
        public electronService: ElectronService,
        private router: Router,
        private ngZone: NgZone,
  	) { }

    displayedColumns = ['emotion', 'timestamp'];
    dataSource = new MatTableDataSource<Emotion>(this.emotions);
    @ViewChild(MatSort, {static: true}) sort: MatSort;

  	ngOnInit() {
        this.getProfilePic();
  		this.getCustomer();
        this.getEmotions();

        // this.electronService.ipcRenderer.on('NEW_CUSTOMER', (_, data) => {
        //     this.getEmotions();
        // })

        // this.electronService.ipcRenderer.on("NOTIFICATION_CLICKED", 
        //     (_, data) => {
        //         // this.customer = null;
        //         // this.emotions = [];
        //         // this.id = this._route.snapshot.paramMap.get('id');
        //         // // this.getProfilePic();
        //         // this.getCustomer();
        //         // this.getEmotions();
        //         this.ngZone.run(() => this.router.navigateByUrl('/profile/'+data)).then();
        // })
  	}

    getProfilePic(): void{
        this._http.getProfilePic(this.id)
            .subscribe(data => {
                this.profile_pic = 'data:image/png;base64,'+data;
            },
            error => {
                this.profile_pic = 'data:image/png;base64,'+error.error.text;
            });
    }

    goBack(): void {
        this.location.back();
    }

  	getCustomer(): void{
  		this._custSer.getCustomer(this.id)
  			.subscribe(customer => {
                this.customer = customer;
                this.profile_pic = this.customer.image;
            });
  	}

    getEmotions(): void{
        this._emoSer.getEmotions(this.id)
            .subscribe(emotions => this.dataSource.data = emotions);
    }
}
