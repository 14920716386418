import { Component, 
    OnInit, 
    ViewChild, 
    NgZone,
    ChangeDetectorRef } from '@angular/core';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { MatTable } from '@angular/material';

import { 
	Customer, 
	CustomerService,
    ElectronService,
} from '../core/services';


@Component({
    selector: 'app-notifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.component.scss']
})

export class NotificationsComponent implements OnInit{

    CUSTOMERS: Customer[] = [];
    constructor ( 
        private _custSer: CustomerService, 
        public electronService: ElectronService,
        private router: Router,
        private ngZone: NgZone,
        private cd: ChangeDetectorRef,
        ){}

    displayedColumns = ['image', 'age', 'gender', 'emotion', 'timestamp', 'camera'];
    dataSource = new MatTableDataSource<Customer>(this.CUSTOMERS);
    @ViewChild(MatSort, {static: true}) sort: MatSort;

    custSub : any

    goTo(customer: Customer): void{
        customer.read = true;
        customer.updated = false;
        this._custSer.updateCustomer(customer);
        this.ngZone.run(() => this.router.navigateByUrl('/profile/'+customer.id)).then();
    }

    applyClass(customer: Customer): string{
        if(customer.latest == true)
            return "latest";
        else if(customer.read == false)
            return "unread";
        else if(customer.updated == true)
            return "updated";
    }


    ngOnInit() {
        this.dataSource.sort = this.sort;
        
        this._custSer.getCustomers()
            .subscribe(customers => this.dataSource.data = customers)
    
        // this.electronService.ipcRenderer.on("NEW_CUSTOMER", (_, data) => {
        //     this._custSer.getCustomers()
        //         .subscribe((customers: Customer[]) => {
        //             if(customers.length > 0)
        //             {
        //                 this.dataSource.data = [...customers];
        //                 // this.cd.detectChanges();
        //                 this.cd.markForCheck();
        //             }
                        
        //         })

        // });
    }
}