import { Injectable } from '@angular/core';

// If you import a module but never use any of the imported values other than as TypeScript types,
// the resulting javascript file will look as if you never imported the module at all.
import { 
    ipcRenderer, 
    webFrame, 
    remote,
    IpcMessageEvent 
} from 'electron';

import * as childProcess from 'child_process';
import * as fs from 'fs';
import {
    START_NOTIFICATION_SERVICE,
    NOTIFICATION_SERVICE_STARTED,
    NOTIFICATION_SERVICE_ERROR,
    NOTIFICATION_RECEIVED,
    TOKEN_UPDATED,
} from 'electron-push-receiver/src/constants';

import { 
    HttpService 
} from '../http/http.service';

// import { CustomerService } from '../customer/customer.service';
// import { EmotionService } from '../emotions/emotion.service';

@Injectable({
    providedIn: 'root'
})
export class ElectronService {
    ipcRenderer: typeof ipcRenderer;
    webFrame: typeof webFrame;
    remote: typeof remote;
    childProcess: typeof childProcess;
    fs: typeof fs;

    get isElectron() {
        return window && window.process && window.process.type;
    }

    constructor(
        private _http: HttpService,
    ) {
        // Conditional imports
        if (this.isElectron) {
            this.ipcRenderer = window.require('electron').ipcRenderer;
            this.webFrame = window.require('electron').webFrame;
            this.remote = window.require('electron').remote;

            this.childProcess = window.require('child_process');
            this.fs = window.require('fs');
            // Start service
            const senderId = '831320808482' // <-- replace with FCM sender ID from FCM web admin under Settings->Cloud Messaging
            console.log('starting service and registering a client')
            this.ipcRenderer.send(START_NOTIFICATION_SERVICE, senderId)


            // Listen for service successfully started
            this.ipcRenderer.on(NOTIFICATION_SERVICE_STARTED, (_, token) => {
                console.log('service successfully started', token);
                // this.ipcRenderer.send("SHARE_TOKEN", token);
                // this._http.shareToken(token)
                //     .subscribe(data => {
                //         console.log(data);
                //     },
                //     error => {
                //         console.log(error);
                //     });
            })

            // Handle notification errors
            this.ipcRenderer.on(NOTIFICATION_SERVICE_ERROR, (_, error) => {
                console.log('notification error', error)
            })

            // Send FCM token to backend
            this.ipcRenderer.on(TOKEN_UPDATED, (_, token) => {
                console.log('token updated', token)
                // ipcRenderer.send("SHARE_TOKEN", token);
                this._http.shareToken(token);
            })

            // Display notification
            this.ipcRenderer.on(NOTIFICATION_RECEIVED, (_, serverNotificationPayload) => {
                // console.log("NEW NEW_CUSTOMER notification received.", serverNotificationPayload.data)
                this.ipcRenderer.send("NEW_CUSTOMER", serverNotificationPayload.data);

                // check to see if payload contains a body string, if it doesn't consider it a silent push
                if (serverNotificationPayload.notification.body) {
                    // payload has a body, so show it to the user
                    let myNotification = new Notification(serverNotificationPayload.notification.title, {
                        body: serverNotificationPayload.notification.body
                    })

                    myNotification.onclick = () => {
                        this.ipcRenderer.send('NOTIFICATION_CLICKED', 
                            serverNotificationPayload.data.id)
                    }
                } else {
                    // payload has no body, so consider it silent (and just consider the data portion)
                    console.log('do something with the key/value pairs in the data', serverNotificationPayload.data)
                }

            });
        }
    }
}
