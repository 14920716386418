import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PageNotFoundComponent } from './shared/components';
import { LoginComponent } from './login/login.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
    { path: '', component: LoginComponent},
    { path: 'notifications', component: NotificationsComponent},
    { path: 'profile/:id', component: ProfileComponent},
    { path: '', pathMatch: "full", redirectTo: 'login' },
    {
    path: '**',
    component: PageNotFoundComponent
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
