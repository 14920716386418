 import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Customer, Emotion } from '../customer/customer';
import emotions from '../../../../assets/emotions.json';


@Injectable({
  providedIn: 'root'
})
export class EmotionService {

	emotions: Emotion[] = emotions;
	customer: Customer;

	constructor() { }

	getEmotions(id: string): Observable<Emotion[]>{
		return of(this.emotions.filter(emotion => emotion.customer === id));
	}

	addEmotion(emotion: Emotion): void{
		this.emotions.push(emotion);
	}
}
