import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HttpService } from '../core/services';
import { AppComponent } from '../app.component';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

	username = "";
    password = "";
	constructor(
		private router: Router,
		private http: HttpService,
		private appc: AppComponent,
		) { }

	ngOnInit() {}

	login (username, password) {
        this.router.navigateByUrl('/notifications');
        var resp = this.http.getAuthenticated(username, password);
        resp.pipe(first())
            .subscribe(data => { 
                console.log("S ", data)
                },
                error => {
                    if(error.status == 201)
                    {
                        console.log(error.error.text);
                        this.appc.logedIn = true;
                        this.router.navigateByUrl('/notifications');
                    }
                    else{

                    }
                })
    }

}
