import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    private loginURL = "https://ec2-15-188-3-68.eu-west-3.compute.amazonaws.com:8443/cas/v1/tickets";
    private picURL = "https://ec2-15-188-3-68.eu-west-3.compute.amazonaws.com:8443/facade/public/profilePicture/";
    private tokenURL = "https://ec2-15-188-3-68.eu-west-3.compute.amazonaws.com:8443/facade/public/token/";

    httpOptions = {
        headers: new HttpHeaders({ 
            'Content-Type': 'Application/x-www-form-urlencoded',
            "Accept": "application/json",
            "Access-Control-Allow-Origin": "*",
        })
    };

    constructor(private http: HttpClient) { }

    getAuthenticated(username, password) {
        const data = new URLSearchParams();
        data.set('username', username);
        data.set('password', password);
        return this.http.post<any>(this.loginURL, data.toString(), this.httpOptions)
    }

    getProfilePic(id){
        var pic_url = this.picURL + id;
        return this.http.get<any>(pic_url); 
    }

    shareToken(token){
        const data = JSON.stringify({ "token": token , "site": "1"});
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            })
        };

        return this.http.post<any>(this.tokenURL, data.toString(), httpOptions);
    }
}
