import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Customer } from './customer';
import { HttpService } from '../http/http.service';
import customers from '../../../../assets/customers.json';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

    CUSTOMERS: Customer[] = customers;
    customer : Customer;
    constructor(
        private _http: HttpService,
        ) { }

    getCustomers() : Observable<Customer[]> {
        return of(this.CUSTOMERS);
    }

    getCustomer(id: string): Observable<Customer>{
        return of(this.CUSTOMERS.find(customer => customer.id === id));
    }

    updateCustomer(customer: Customer): void{
        const index = this.CUSTOMERS.findIndex((cust => cust.id == customer.id))
        this.CUSTOMERS[index].age = customer.age;
        this.CUSTOMERS[index].gender = customer.gender;
        this.CUSTOMERS[index].emotion = customer.emotion;
        // this.CUSTOMERS[index].timestamp = customer.timestamp;
        this.CUSTOMERS[index].camera = customer.camera;
        // this.CUSTOMERS[index].id = customer.id;
        this.CUSTOMERS[index].image = customer.image;
        if(customer.latest != undefined)
        this.CUSTOMERS[index].latest = customer.latest;
        if(customer.read != undefined)
        this.CUSTOMERS[index].read = customer.read;
        if(customer.updated != undefined)
        this.CUSTOMERS[index].updated = customer.updated;
    }

    addCustomer(customer: Customer): void{
        if(this.CUSTOMERS.find(cust => cust.id === customer.id) == undefined)
        {
            this._http.getProfilePic(customer.id)
                .subscribe(data => {
                    customer.image = 'data:image/png;base64,'+data;
                    customer.latest = true;
                    customer.read = false;
                    customer.updated = false;
                    this.CUSTOMERS.map(customer => customer.latest = false);
                    this.CUSTOMERS.unshift(customer);
                }, error => {
                    customer.image = 'data:image/png;base64,'+error.error.text;
                    customer.latest = true;
                    customer.read = false;
                    customer.updated = false;
                    this.CUSTOMERS.map(customer => customer.latest = false);
                    this.CUSTOMERS.unshift(customer);
                });
        }
        else{
            customer.updated = true;
            this.updateCustomer(customer);
        }
    }
}