export class Customer{
	age: number;
    gender: string;
    emotion: string;
    timestamp: string;
    camera: string;
    id: string;
    image: string;
    latest: boolean;
    read: boolean;
    updated: boolean;
}


export class Emotion{
	customer : string;
	emotion : string;
	timestamp: string;
}