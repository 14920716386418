import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TimezoneService {

  	constructor() { }

  	toUserTimezone(utcDate){
      console.log("PARAM ",utcDate)    
  		var timezoneOffset = new Date().getTimezoneOffset() * -1;    
      console.log("OFFSET ", timezoneOffset);
  		var tojsDateObj = new Date(utcDate);
      console.log("JS DATE OBJECT ", tojsDateObj); 
  		var convertedDate = new Date(tojsDateObj.getTime() + (timezoneOffset * 60000));
      console.log("final conversion ", convertedDate);
  		return convertedDate.toLocaleString();
  	};


  	toUTCTimezone(zoneDate){    
  		var timezoneOffset = new Date().getTimezoneOffset() * -1;    
  		var tojsDateObj = new Date(zoneDate);    
  		var convertedDate = new Date(tojsDateObj.getTime() - (timezoneOffset * 60000));    
  		return convertedDate;
  	};
}
